import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockKeyhole, User } from 'lucide-react';
import logo from '../images/upflogo_with_typeandacronym-rv0602-2011-1080p-01 1.png';
import { notification } from 'antd';
interface LoginUser {
    data: {
        token: string;
        [key: string]: any;
    };
    id: number;
    name: string;
    email: string;
}

const loginUser = async (email: string, password: string): Promise<LoginUser | any> => {
    try {
        const apiUrl = 'https://api.upfafrica.org/admin/auth/login';
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: email,
                password: password,
            }),
        });

        if (!response.ok) {
            throw new Error('Login failed.');
        }

        const user = await response.json();
        
        if (user?.data && user.data.token) {
            window.localStorage.setItem("user", JSON.stringify(user.data));
            window.sessionStorage.setItem("token", user.data.token);
            return user;
        } else {
            throw new Error('Invalid user data or missing token.');
        }
    } catch (error) {
        throw error;
    }
};

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const user = await loginUser(email, password);
            notification.success({
                      message: 'Success',
                      description: 'Logged in successfully',
                    });
            if (user?.data && user.data.token) {
                navigate('/');
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: `login failed check your credentials and try again${(error as Error).message}`,
              });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 flex items-center justify-center p-4">
            <div className="max-w-md w-full">
                <div className="text-center bg-blue-300 rounded-t-md">
                    <img 
                        src={logo} 
                        alt="UPF Logo" 
                        className="mx-auto mb-6"
                    />
                    <h1 className="text-3xl font-serif text-gray-800 mb-2">Universal Peace Federation</h1>
                    <p className="text-gray-600">Admin Portal</p>
                </div>
                
                <div className="bg-white  shadow-md p-8 border border-gray-100">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="relative">
                            <User className="absolute left-3 top-1/2 -translate-y-1/2 text-blue-400" size={20} />
                            <input
                                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:border-blue-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50 focus:outline-none transition-colors placeholder:text-gray-400"
                                type="text"
                                placeholder="Username"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        
                        <div className="relative">
                            <LockKeyhole className="absolute left-3 top-1/2 -translate-y-1/2 text-blue-400" size={20} />
                            <input
                                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:border-blue-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50 focus:outline-none transition-colors placeholder:text-gray-400"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full bg-blue-400 text-white py-3 rounded-lg font-medium transition-colors hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isLoading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Signing in...
                                </span>
                            ) : (
                                'Sign In'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;