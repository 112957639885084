import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import LikeButton from './likes';
import RateButton from "./rating";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { CalendarOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons';
import {  Input, Modal, Pagination as AntPagination,  notification,Tabs,Button } from 'antd';
import User from "../images/conference.jpg";
import { FadeLoader } from "react-spinners";
import { FcDeleteDatabase } from "react-icons/fc";
import { useSummits } from '../../contextApis/SummitProvider';
import { useDebounce } from "use-debounce";
interface User {
  userType: number;
}



const { TabPane } = Tabs;
const SummitType = () => {
  const navigate = useNavigate();
  const { summit } = useSummits();
  const nationData = localStorage.getItem('nations');
  const nations = nationData ? JSON.parse(nationData) : [];
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activityIdToDelete, setActivityIdToDelete] = useState(null);
  const {darkMode} = useDarkMode();
  const deleteActivity = async (id:any) => {
    try {
      const response = await fetch(`https://api.upfafrica.org/admin/activity/softDelete/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      });

      if (!response.ok) throw new Error('Failed to delete activity');

      notification.success({ message: 'Activity deleted successfully' });
    } catch (error) {
      console.error('Error deleting activity:', error);
      notification.error({ message: 'Failed to delete activity' });
    }
  };

  const showDeleteModal = (id:any) => {
    setActivityIdToDelete(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    if (activityIdToDelete) {
      await deleteActivity(activityIdToDelete);
      setIsModalVisible(false);
    }
  };

  const getNationName= (id: number) => {
    const nation:any = nations.find((nation:any) => nation.id === id);
    return nation ? nation.name : 'Unknown';
  };

  const categorizeEvents = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return {
      future: summit.filter((summit:any) => new Date(summit.date) > currentDate),
      present: summit.filter((summit:any) => {
        const summitDate = new Date(summit.date);
        return summitDate.toDateString() === currentDate.toDateString();
      }),
      past: summit.filter((summit:any) => new Date(summit.date) < currentDate)
    };
  };

  const renderSummit = (summit:any) => (
    <div key={summit.id} className="flex flex-col bg-white  border-2 dark:border-gray-700 rounded-lg shadow-lg overflow-hidden">
      <img
        src={summit.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/default-profile-image.png'}
        alt={summit.name}
        className="h-48 w-full object-cover"
      />
      <div className="p-4 flex flex-col h-full">
        <Link to={`/Summits/${summit.id}`} className="text-lg font-semibold hover:underline mb-2">{summit.name || 'No Name'}</Link>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Nation: {getNationName(summit.nationId)}</p>
        <p className="text-sm mb-4"><span className="font-semibold">Theme:</span> {summit.theme}</p>
        <div className="mt-auto">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-semibold mb-1">Rating:</p>
              <RateButton />
            </div>
            <div className="flex items-center space-x-2">
              <LikeButton />
              <FaRegShareFromSquare className="w-5 h-5 cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderEventList = (events:any) => (
    <>
    <div className="text-center mt-8 mb-8">
    <button
      onClick={() => navigate('/add-event')}
      className="px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
    >
      + Add New Event
    </button>
  </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {events.map(renderSummit)}
    </div>
    </>
  );

  if (loading && summit.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color="#367fd6" />
      </div>
    );
  }

  if (summit.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <FcDeleteDatabase className="w-24 h-24 mb-4" />
        <p className="text-2xl font-semibold mb-4">No data found. Check your internet connection.</p>
        <button
          onClick={() => navigate('/add-event')}
          className="px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300"
        >
          + Add Event
        </button>
      </div>
    );
  }

  const { future, present, past } = categorizeEvents();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">UPF Africa Events</h1>
      <Tabs defaultActiveKey="1" centered className="mb-8">
        <TabPane tab={<p className={`text-lg text-black  hover:text-blue-900 ${darkMode ? 'text-white' : ''}`}>Future Events ({future.length})</p>} key="1">
          {renderEventList(future)}
        </TabPane>
        <TabPane tab={<span className={`text-lg text-black  hover:text-blue-900 ${darkMode ? 'text-white  hover:text-blue-900' : ''}`}>Present Events ({present.length})</span>} key="2">
          {renderEventList(present)}
        </TabPane>
        <TabPane tab={<span className={`text-lg text-black  hover:text-blue-900 ${darkMode ? 'text-white   hover:text-blue-900' : ''}`}>Past Events ({past.length})</span>} key="3">
          {renderEventList(past)}
        </TabPane>
      </Tabs>
     
      <Modal
        title="Delete Event"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
      >
        <p>Are you sure you want to delete this event?</p>
      </Modal>
    </div>
  );
};



interface User {
  id: string;
  name: string;
  position?: string;
  upfPosition?: string;
  nationality: number;
  avatar?: string;
}

interface Summit {
  id: string;
  name: string;
  host: string;
  city: string;
  date: string;
  description: string;
  coverImage: string;
  gallery?: string[];
  participantsId: string[];
}

interface SummitsDetailProps {
  users: User[];
}
const downloadSummitDetailsPDF = async (summit: any, participants: any[]) => {
  // Create a new jsPDF instance
  const doc = new jsPDF('p', 'mm', 'a4');
  
  // Set up document properties
  doc.setFontSize(12);
  
  // Helper function to add wrapped text
  const addWrappedText = (text: string, x: number, y: number, maxWidth: number) => {
    const lines = doc.splitTextToSize(text, maxWidth);
    doc.text(lines, x, y);
    return y + (lines.length * 7); // Estimate line height
  };
  
  // Title and Basic Information
  doc.setFontSize(18);
  doc.text(summit.name, 20, 20);
  
  doc.setFontSize(12);
  let yPos = 30;
  
  // Add basic details
  yPos = addWrappedText(`Date: ${new Date(summit.date).toLocaleDateString()}`, 20, yPos, 170);
  yPos = addWrappedText(`Location: ${summit.city}`, 20, yPos, 170);
  yPos = addWrappedText(`Host: ${summit.host}`, 20, yPos, 170);
  yPos = addWrappedText(`Status: ${summit.status}`, 20, yPos, 170);
  
  // Description
  doc.setFontSize(14);
  doc.text('Description', 20, yPos + 10);
  doc.setFontSize(12);
  yPos = addWrappedText(summit.description || 'No description available', 20, yPos + 20, 170);
  
  // Participants
  doc.setFontSize(14);
  doc.text('Participants', 20, yPos + 10);
  doc.setFontSize(12);
  
  // Create table header
  const headers = ['No', 'Name', 'Position', 'Nationality'];
  const columnWidths = [15, 50, 50, 50];
  
  yPos += 20;
  
  // Table headers
  headers.forEach((header, i) => {
    doc.text(header, 20 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0), yPos);
  });
  
  yPos += 10;
  doc.line(20, yPos, 190, yPos); // Horizontal line under headers
  
  // Participants table
  participants.forEach((participant, index) => {
    yPos += 10;
    
    // Check if we need a new page
    if (yPos > 270) {
      doc.addPage();
      yPos = 20;
    }
    
    const participantData = [
      (index + 1).toString(),
      participant.name,
      participant.position || participant.upfPosition || 'N/A',
      participant.nationalityName || 'N/A'
    ];
    
    participantData.forEach((data, i) => {
      doc.text(data, 20 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0), yPos);
    });
  });
  
  // Save the PDF
  doc.save(`${summit.name}_Details.pdf`);
};
const SummitsDetail: React.FC<SummitsDetailProps> = ({ users }) => {
  const { id } = useParams<{ id: string }>();
  const [summit, setSummit] = useState<Summit | null>(null);
  const [participants, setParticipants] = useState<User[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [debouncedFilterValue] = useDebounce(filterValue, 300);
  const [isLoading, setIsLoading] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const nationData = localStorage.getItem('nations');
  const nationList: { id: number; name: string }[] = nationData ? JSON.parse(nationData) : [];

  useEffect(() => {
    const fetchSummitDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://api.upfafrica.org/admin/activity/${id}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch summit details');
        const data = await response.json();
        setSummit(data.data);
      } catch (error) {
        console.error('Error fetching summit details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) fetchSummitDetails();
  }, [id]);
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
useEffect(() => {
  if (summit && users && Array.isArray(summit.participantsId)) {
    const filteredParticipants = users
      .filter(user => summit.participantsId.includes(user.id))
      .filter(user =>
        user.name.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
        (user.position && user.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
        (user.upfPosition && user.upfPosition.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
        getNationNameById(user.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase())
      );
    setParticipants(filteredParticipants);
  } else {
    setParticipants([]);
  }
}, [summit, users, debouncedFilterValue]);


  const getNationNameById = (id: number) => {
    const nation = nationList.find(nation => nation.id === id);
    return nation ? nation.name : 'Unknown';
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setCurrentPage(1); 
  };
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color="#3B82F6" />
      </div>
    );
  }

  if (!summit) {
    return <div className="text-center text-2xl mt-10">Summit not found</div>;
  }
  const paginatedparticipantsData = participants && participants.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page: number) => {
      setCurrentPage(page);
  };
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="relative h-96">
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            className="h-full"
          >
            <SwiperSlide>
              <img
                src={`https://fm.upfafrica.org${summit.coverImage}`}
                alt={summit.name}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
            {summit?.gallery && summit.gallery.map((image: string, index: number) => (
              <SwiperSlide key={index}>
                <img
                  src={`https://fm.upfafrica.org${image}`}
                  alt={`Gallery image ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute inset-0 bg-black  bg-opacity-50 flex items-end">
            <div className="p-6 text-white">
              <h1 className="text-4xl font-bold mb-2">{summit.name}</h1>
              <p className="text-xl">{summit.host}</p>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="flex flex-wrap gap-6 text-gray-600 mb-6">
            <div className="flex items-center">
              <CalendarOutlined className="mr-2" />
              {new Date(summit.date).toLocaleDateString()}
            </div>
            <div className="flex items-center">
              <EnvironmentOutlined className="mr-2" />
              {summit.city}
            </div>
            <div className="flex items-center">
              <TeamOutlined className="mr-2" />
              {participants.length} Participants
            </div>
            <Button
            className="bg-blue-200 hover:bg-blue-300 text-blue-700"
            onClick={() => downloadSummitDetailsPDF(summit, participants)}
          >
            <DownloadOutlined /> Download PDF
          </Button>
          </div>

          <div className="mb-8  text-black">
            <h2 className="text-2xl font-semibold mb-4" style={getTitleStyle()}>Description</h2>
            <div className="prose max-w-none">
              {isDescriptionExpanded && summit.description? summit.description :summit.description && summit.description.slice(0, 250)}
              {summit.description && summit.description.length > 250 && (
                <button 
                  onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)} 
                  className="text-blue-500 hover:text-blue-700 font-semibold mt-2 block"
                >
                  {isDescriptionExpanded ? 'Show Less' : 'Read More'}
                </button>
              )}
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4" style={getTitleStyle()}>Participants</h2>
            <Input
              placeholder="Search by name, position, or nationality"
              value={filterValue}
              onChange={handleInputChange}
              className="mb-4"
            />
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Position</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nationality</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200  text-black">
                  {paginatedparticipantsData.length > 0 ? paginatedparticipantsData.map((participant, index) => (
                    <tr key={participant.id}>
                      <td className="px-6 py-4 whitespace-nowrap">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{participant.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{participant.position || participant.upfPosition}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{participant.nationality && getNationNameById(participant.nationality)}</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center">No participants found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <AntPagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={participants.length}
              onChange={handlePageChange}
              className="mt-4 text-center"
            />
          </div>

        </div>
      </div>

      
    </div>
  );
};
export { SummitType, SummitsDetail };
