import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronLeft, FaSearch, FaTimes, FaUser, FaCalendar } from 'react-icons/fa';
import { useDebounce } from 'use-debounce';
import axios from "axios";
import { number } from 'echarts';
import { Select } from 'antd';
const { Option } = Select;
interface EventFormData {
    name: string;
    type: string;
    theme: string;
    city: string;
    host: string;
    duration:number;
    description:string;
    numberParticipants:number;
    participantsId:any;
    nationId: number;
    regionId: number;
    subRegionId: number;
    date: Date | null;
    coverImage: string;
  }
  
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
interface DropdownOption {
    id: number;
    name: string;
  }
  interface UserDropdownOption {
    id: string;
    name: string;
  }
  interface UserListProps {
    users: User[];
    deleteUserProp:any;
}
const SummitEdit: React.FC<UserListProps> = ({users,deleteUserProp}) => {
  const { id } = useParams<{ id: string }>();
  const [fetchedData, setFetchedData] = useState<EventFormData | null>(null);
  const [formData, setFormData] = useState<EventFormData | null>(null);
  const storedUser = localStorage.getItem('user');
  const [usersOptions, setUsersOptions] = useState<any[]>([]);
  const regionData = localStorage.getItem('regions');
  const regionOptions = regionData ? JSON.parse(regionData) : [];
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionOptions = subRegionData ? JSON.parse(subRegionData) : [];
  const nationData = localStorage.getItem('nations');
  const nationalityOptions= nationData ? JSON.parse(nationData) : [];
  const [userFilter, setUserFilter] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue] = useDebounce(filterValue, 100);
  const [massParticipants, setMassParticipants] = useState<string[]>([]);
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [imageURL, setImageURL] = useState<string>('');
  const navigate = useNavigate();
  console.log(userFilter)
  useEffect(() => {
    const fetchActivityData = async () => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      try {
        const response = await fetch(`https://api.upfafrica.org/admin/activity/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.error('Failed to fetch activity data');
          return;
        }
        const responseData = await response.json();
        console.log("fetchedActivityData",responseData)
      const { primaryTag,duration,numberParticipants,status,participantsId,...filteredData } = responseData.data;
      let newUserFilter:any = [];
if(Array.isArray(participantsId)){
    participantsId.forEach((participant:any) => {
        newUserFilter.push(participant);
    });
}
// Update the userFilter state with the new array
setUserFilter(newUserFilter);

      const updatedData = {
        ...filteredData,
        status:'Pending',
        duration: (duration && !isNaN(duration) && duration !== "") ? duration : 10,
        numberParticipants: (numberParticipants && !isNaN(numberParticipants) && numberParticipants !== "") ? numberParticipants : 10,
        primaryTag: (primaryTag && !isNaN(primaryTag) && primaryTag !== "") ? primaryTag : 10,
      };
      setImageURL(updatedData.coverImage);
      setFetchedData(updatedData);
      setFormData(updatedData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    

    fetchActivityData();
  }, [id]);
 
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token not found in sessionStorage');
          return;
        }

        
        if (users) {
          const filteredData = users.filter((item: any) => {
            if (item) {
              const name = item.name ? item.name.toLowerCase() : '';
              return (
                name.includes(debouncedFilterValue.toLowerCase())
              );
            } else {
              return false;
            }
          });
          setUsersOptions(filteredData);
        }
        
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, [debouncedFilterValue]);
  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({
      ...prevFormData!,
      date,
    }));
  };
  
  const handleSelectChange = (name: keyof EventFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData!,
      [name]: name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  
  const handleChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const { name, value } = e.target;
  
    // For non-picture inputs, update form data as usual
    setFormData({
      ...formData!,
      [name]:  name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`, // Replace with your actual token
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();
console.log(userFilter)
  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return;
  }
console.log(formData)
  try {
    const response = await fetch(`https://api.upfafrica.org/admin/activity/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ ...formData!, coverImage: imageURL,participantsId:userFilter,})
    });

    if (!response.ok) {
      const Error = await response.json();
      console.error('Failed to submit form', Error);
      return;
    }
    const responseData = await response.json();
    navigate(`/Summits/${id}`);
    console.log(responseData);
  } catch (error) {
    console.error('Error:', error);
  }
};
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilterValue(e.target.value);
};
  
  if (!fetchedData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
  }
  const back=()=>{
    navigate(-1)
   }
   const handleUserCheckboxChange = (subName: string) => {
    if (userFilter.includes(subName)) {
        setMassParticipants(userFilter.filter((name) => name !== subName));
    } else {
        setMassParticipants([...userFilter, subName]);
    }
};
return (
  <div className="min-h-screen bg-gray-50 p-8">
    <button 
      onClick={back}
      className="flex items-center text-gray-700 hover:text-blue-600 transition-colors mb-8 group"
    >
      <FaChevronLeft className="w-5 h-5 mr-2 group-hover:transform group-hover:-translate-x-1 transition-transform" />
      <span className="text-xl font-semibold">Back</span>
    </button>

    {!fetchedData ? (
      <div className="flex justify-center items-center h-96">
        <FadeLoader color="#4F46E5" />
      </div>
    ) : (
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Edit Summit</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Basic Information Section */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder="Summit name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Type</label>
              <input
                type="text"
                name="type"
                value={formData?.type}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Summit type"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Theme</label>
              <input
                type="text"
                name="theme"
                value={formData?.theme}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Summit theme"
              />
            </div>

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">Date</label>
              <div className="relative">
                <DatePicker
                  selected={formData?.date}
                  onChange={handleDateChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <FaCalendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Location Section */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Host</label>
              <input
                type="text"
                name="host"
                value={formData?.host}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Host name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">City</label>
              <input
                type="text"
                name="city"
                value={formData?.city}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="City"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Region</label>
              <select
                name="regionId"
                value={formData?.regionId}
                onChange={handleSelectChange('regionId')}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a region</option>
                {regionOptions.map((region:DropdownOption ) => (
                  <option key={region.id} value={region.id}>{region.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Sub-Region</label>
              <select
                name="subRegionId"
                value={formData?.subRegionId}
                onChange={handleSelectChange('subRegionId')}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a sub-region</option>
                {subRegionOptions.map((subRegion:DropdownOption ) => (
                  <option key={subRegion.id} value={subRegion.id}>{subRegion.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Description Section - Full Width */}
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
          <textarea
            name="description"
            value={formData?.description}
            onChange={handleChange}
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Describe the summit..."
          />
        </div>

        {/* Participants Section - Full Width */}
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Participants</label>
          <div className="relative">
            <input
              type="text"
              placeholder="Search participants..."
              value={filterValue}
              onChange={handleInputChange}
              className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Selected Participants */}
          <div className="mt-4 flex flex-wrap gap-2">
            {userFilter.map(userId => {
              const user = usersOptions.find(u => u.id === userId);
              return user ? (
                <div
                  key={user.id}
                  className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm"
                >
                  <FaUser className="mr-2 w-4 h-4" />
                  <span>{user.name}</span>
                  <button
                    type="button"
                    onClick={() => setUserFilter(userFilter.filter(id => id !== userId))}
                    className="ml-2 text-blue-600 hover:text-blue-800"
                  >
                    <FaTimes className="w-4 h-4" />
                  </button>
                </div>
              ) : null;
            })}
          </div>

          {/* Participants Dropdown */}
          {filterValue && (
            <div className="mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto">
              {usersOptions
                .filter(user => !userFilter.includes(user.id))
                .map(user => (
                  <div
                    key={user.id}
                    className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center"
                    onClick={() => setUserFilter([...userFilter, user.id])}
                  >
                    <FaUser className="mr-2 text-gray-400" />
                    <span>{user.name}</span>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Image Upload Section */}
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Cover Image</label>
          <div className="mt-2 flex items-center space-x-6">
            <div className="flex-shrink-0">
              {imageURL && (
                <img
                  src={imageURL}
                  alt="Cover"
                  className="h-32 w-32 object-cover rounded-lg"
                />
              )}
            </div>
            <div className="flex-1">
              <input
                type="file"
                name="coverImage"
                onChange={handleImageChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </form>
    )}
  </div>
);
};

export default SummitEdit;
