import React, { useState, useEffect } from 'react';
import { Select, Input, Empty, Modal, Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from "react-icons/fa";
import NationalityChart from './nationalityCharts';
import { useNavigate } from "react-router-dom";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FadeLoader } from 'react-spinners';
import { Users, Award, ContactIcon, UserCircle } from 'lucide-react';
interface User {
  id: number;
  userType: number;
  subRegionId: number;
  regionId: number;
  nationality: number;
  name: string;
  position?: string;
  upfPosition?: string;
  leadershipStatus?: boolean;
  ambassadorStatus?: boolean;
}

interface Nation {
  id: number;
  name: string;
}

interface UserListProps {
  users: User[];
  deleteUserProp: (id: number) => Promise<void>;
}

const { Option } = Select;

const SrWest: React.FC<UserListProps> = ({ users, deleteUserProp }) => {
  const [usersData, setUsersData] = useState<User[]>([]);
  const [totalRegionId2, setTotalRegionId2] = useState<number>(0);
  const [filterValue, setFilterValue] = useState<string>('');
  const [nationalityFilter, setNationalityFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 50;

  const { t } = useTranslation();
  const { darkMode } = useDarkMode();
  const navigate = useNavigate();

  // Parse stored data
  const storedUser = localStorage.getItem('user');
  const nationData = localStorage.getItem('nations');
  const User: User | null = storedUser ? JSON.parse(storedUser) : null;
  const nationList: Nation[] = nationData ? JSON.parse(nationData) : [];
  const token = sessionStorage.getItem('token');
const numberAmbassador=usersData.filter((user) => user.ambassadorStatus).length;
const numberContacts=usersData.filter((user) => !user.ambassadorStatus && !user.leadershipStatus).length;
const numberLeadership=usersData.filter((user) => user.leadershipStatus).length;
const totalUsers=usersData.length;
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [navigate, token]);

  // Calculate nationality counts for chart
  const calculateNationalityCounts = () => {
    const nationalityCounts: { [key: number]: number } = {};
    usersData.forEach((user) => {
      if (user.nationality) {
        nationalityCounts[user.nationality] = (nationalityCounts[user.nationality] || 0) + 1;
      }
    });
    return nationalityCounts;
  };

  useEffect(() => {
    const filteredUsers = users.filter((item: User) =>
      User?.userType === 8
        ? item.subRegionId === 15
        : item.subRegionId === User?.subRegionId
    );
    setUsersData(filteredUsers);
  }, [users, User]);

  // Calculate total for region 2
  useEffect(() => {
    const totalRegion2 = usersData.filter((user) => user.subRegionId === 11).length;
    setTotalRegionId2(totalRegion2);
  }, [usersData]);

  const getNationNameById = (id: number): string => {
    if (!id || !Array.isArray(nationList)) return '';
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
  };

  const handleDelete = async () => {
    if (userIdToDelete) {
      try {
        await deleteUserProp(userIdToDelete);
        setIsModalVisible(false);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const filteredUsers = usersData.filter(user => {
    const statusCheck = 
      statusFilter === 'leadership' ? user.leadershipStatus :
      statusFilter === 'ambassador' ? user.ambassadorStatus :
      statusFilter === 'Contacts' ? (!user.ambassadorStatus && !user.leadershipStatus) :
      true;

    const nationalityCheck = nationalityFilter.length === 0 || 
      (user.nationality && nationalityFilter.includes(getNationNameById(user.nationality)));

    const searchCheck = !filterValue || 
      (user.name && user.name.toLowerCase().includes(filterValue.toLowerCase())) ||
      (user.position && user.position.toLowerCase().includes(filterValue.toLowerCase())) ||
      (user.nationality && getNationNameById(user.nationality).toLowerCase().includes(filterValue.toLowerCase()));

    return statusCheck && nationalityCheck && searchCheck;
  });

  // Pagination calculation
  const paginatedData = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (usersData.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color="#367fd6" />
      </div>
    );
  }
const stats = [
    {
      title: 'Total Leaders',
      value: numberLeadership,
      icon: UserCircle,
      gradient: 'from-blue-600 to-blue-400',
      shadowColor: 'shadow-blue-200'
    },
    {
      title: 'Total Ambassadors',
      value: numberAmbassador,
      icon: Award,
      gradient: 'from-emerald-600 to-emerald-400',

    },
    {
      title: 'Total Contacts',
      value: numberContacts,
      icon: ContactIcon,
      gradient: 'from-violet-600 to-violet-400',
   
    },
    {
      title: 'Total Users',
      value: totalUsers,
      icon: Users,
      gradient: 'from-orange-600 to-orange-400',
     
    }
  ];
  return (
    <div className="container mx-auto p-2">
      {User?.userType === 8 && (
        <button onClick={() => navigate(-1)} className="flex flex-row mb-4 hover:opacity-80">
          <FaChevronLeft className="w-6 h-6" />
          <p className="text-xl font-semibold ml-3">{t('Back')}</p>
        </button>
      )}

<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
      {stats.map((stat, index) => (
        <div 
          key={index}
          className={`relative bg-white rounded-xl overflow-hidden transition-all duration-300 hover:scale-102 shadow-lg hover:shadow-xl`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div className={`bg-gradient-to-r ${stat.gradient} p-3 rounded-lg`}>
                <stat.icon className="w-6 h-6 text-white" />
              </div>
            </div>
            <div className="space-y-2">
              <h3 className="text-gray-600 text-sm font-medium">
                {stat.title}
              </h3>
              <p className="text-black text-2xl font-semibold">
                {stat.value}
              </p>
            </div>
          </div>
          <div className={`absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r ${stat.gradient}`} />
        </div>
      ))}
    </div>

      <div className="bg-white rounded-lg containerr shadow-lg p-6 w-[95%] mx-auto mb-8">
        <p className="text-2xl font-semibold mb-4">{t('Individuals Per Country')}</p>
        <NationalityChart 
    data={usersData}
    nationList={nationList}
    darkMode={darkMode}
    t={t}
  />
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 w-[95%] mx-auto containerr">
        <div className="flex justify-between mb-6">
          <p className="font-bold text-4xl">{t('SR West')}</p>
          <div className="flex gap-4">
            <Select
              defaultValue=""
              className="w-40"
              onChange={(value: string) => setStatusFilter(value)}
            >
              <Option value="">{t('All Status')}</Option>
              <Option value="leadership">{t('Leadership')}</Option>
              <Option value="ambassador">{t('Ambassador')}</Option>
              <Option value="Contacts">Contacts</Option>
              <Option value="Nation">{t('Nation')}</Option>
            </Select>

            {statusFilter === 'Nation' ? (
              <Select
                mode="multiple"
                placeholder={t('Select Nationality')}
                className="w-64 h-8"
                onChange={(values: string[]) => setNationalityFilter(values)}
                value={nationalityFilter}
              >
                {nationList.map(nation => (
                  <Option key={nation.id} value={nation.name}>
                    {t(nation.name)}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                placeholder={t('Search by name, position, or nationality')}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                className="w-64 h-8"
              />
            )}
          </div>
         
        </div>
        <table className="w-full ">
          <thead className="text-left">
            <tr>
              <th className="py-2">{t('NO')}</th>
              <th className="py-2 px-3">{t('Contact')}</th>
              <th className="py-2">{t('Position')}</th>
              <th className="py-2">{t('Nationality')}</th>
              {[8, 4, 7, 6].includes(User?.userType || 0) && <th className="py-2">{t('Action')}</th>}
            </tr>
          </thead>
          {paginatedData.length > 0 ? (
            <tbody>
              {paginatedData.map((user, index) => (
                <tr key={user.id}>
                  <td className="py-2 px-1">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td className="py-2 px-3">{user.name}</td>
                  <td className="py-2 w-2/5">
                    {((user.position || user.upfPosition) || '').split(' ').slice(0, 120).join(' ')}
                  </td>
                  <td className="py-2 w-1/4">
                    {user.nationality && t(getNationNameById(user.nationality))}
                  </td>
                  {[8, 4, 7, 2, 6].includes(User?.userType || 0) && (
                    <td className="py-2 flex flex-row">
                      <FontAwesomeIcon
                        onClick={() => {
                          setUserIdToDelete(user.id);
                          setIsModalVisible(true);
                        }}
                        className="cursor-pointer hover:opacity-80"
                        color="#FF0000"
                        icon={faTrash}
                      />
                      <CiEdit
                        className="cursor-pointer ml-2 w-11 hover:opacity-80"
                        onClick={() => navigate(`/EditAmbassador/${user.id}`)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Empty description={t('No Data Found')} />
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={filteredUsers.length}
          onChange={(page) => setCurrentPage(page)}
          className="text-center mt-6"
        />

        <Modal
          title={t('Delete User')}
          open={isModalVisible}
          onOk={handleDelete}
          onCancel={() => setIsModalVisible(false)}
          okText={t('Delete')}
          cancelText={t('Cancel')}
          okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
        >
          <p>{t('Are you sure you want to delete this user?')}</p>
        </Modal>
      </div>
    </div>
  );
};

export default SrWest;