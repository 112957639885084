import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronLeft, FaUpload, FaImages } from "react-icons/fa";
import axios from "axios";
import { FadeLoader } from 'react-spinners';
import { notification } from 'antd';

interface EventFormData {
  name: string;
  type: string;
  theme: string;
  status: string;
  city: string;
  host: string;
  nationId: number;
  regionId: number;
  subRegionId: number;
  date: Date | null;
  description: string;
  coverImage: string;
  gallery: string[];
}

interface ValidationErrors {
  [key: string]: string;
}

const EventForm: React.FC = () => {
  const navigate = useNavigate();
  const [imageLoadingGallery, setImageLoadingGallery] = useState(false);
  const [imageLoadingCover, setImageLoadingCover] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [imageURL, setImageURL] = useState<string>('');
  const [gallery, setGallery] = useState<{ url: string }[]>([]);
  const regionOptions = JSON.parse(localStorage.getItem('regions') || '[]');
  const nationalityOptions = JSON.parse(localStorage.getItem('nations') || '[]');
  const subRegionOptions = JSON.parse(localStorage.getItem('subRegions') || '[]');
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const [formData, setFormData] = useState<EventFormData>({
    name: '',
    type: '',
    city: '',
    theme: '',
    coverImage: '',
    description: '',
    gallery: [],
    host: '',
    status: 'Pending',
    nationId: user?.nationality || 1,
    subRegionId: user?.subRegionId || 1,
    regionId: 1,
    date: null,
  });

  // Validation rules
  const validateField = (name: string, value: any): string => {
    switch (name) {
      case 'name':
        return !value ? 'Event name is required' : '';
      case 'type':
        return !value ? 'Event type is required' : '';
      case 'theme':
        return !value ? 'Theme is required' : '';
      case 'city':
        return !value ? 'City is required' : '';
      case 'host':
        return !value ? 'Host is required' : '';
      case 'date':
        return !value ? 'Date is required' : '';
      case 'description':
        return !value ? 'Description is required' : '';
      case 'coverImage':
        return !value ? 'Cover image is required' : '';
      default:
        return '';
    }
  };

  const validateForm = (): boolean => {
    const errors: ValidationErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key as keyof EventFormData]);
      if (error) {
        errors[key] = error;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setTouched(prev => ({ ...prev, [name]: true }));
    
    if (touched[name]) {
      const error = validateField(name, value);
      setValidationErrors(prev => ({ ...prev, [name]: error }));
    }
  };
  const handleDateChange = (date: Date | null) => {
    setFormData({
      ...formData,
      date,
    });
  };


  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('files', file);
    setImageLoadingCover(true);

    try {
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`,
          },
        }
      );

      if (Array.isArray(response.data) && response.data.length > 0) {
        const url = response.data[response.data.length - 1].url;
        setImageURL(url);
        setFormData(prev => ({ ...prev, coverImage: url }));
        notification.success({
          message: 'Success',
          description: 'Image uploaded successfully',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to upload image',
      });
    } finally {
      setImageLoadingCover(false);
    }
  };
  const handleGalleryChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const totalImages = gallery.length + files.length;
    if (totalImages > 9) {
      notification.warning({
        message: 'Warning',
        description: `You can only upload up to 9 images. You currently have ${gallery.length} images.`,
      });
      return;
    }
  
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    setImageLoadingGallery(true);
  
    try {
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`,
          },
        }
      );
  
      if (Array.isArray(response.data)) {
        const newImages = response.data.map(image => ({ url: image.url }));
        const updatedGallery = [...gallery, ...newImages];
  
        setGallery(updatedGallery);
        setFormData(prev => ({
          ...prev,
          gallery: updatedGallery.map(img => img.url),
        }));
  
        notification.success({
          message: 'Success',
          description: 'Images uploaded successfully',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to upload images',
      });
    } finally {
      setImageLoadingGallery(false);
    }
  };
  const handleSelectChange = (name: keyof EventFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setFormData(prev => ({ ...prev, [name]: value }));
    setTouched(prev => ({ ...prev, [name]: true }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouched(Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: true }), {}));

    if (!validateForm()) {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill in all required fields',
      });
      return;
    }

    setIsSubmitting(true);
    const token = sessionStorage.getItem('token');

    if (!token) {
      notification.error({
        message: 'Error',
        description: 'Authentication token not found',
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://api.upfafrica.org/admin/activity/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          gallery: gallery.map(item => item.url),
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error?.message || 'Failed to create event');
      }

      notification.success({
        message: 'Success',
        description: 'Event created successfully',
      });
      navigate('/Summits');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error instanceof Error ? error.message : 'Failed to create event',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFieldError = (fieldName: string) => {
    return touched[fieldName] && validationErrors[fieldName] ? (
      <p className="mt-1 text-sm text-red-600">{validationErrors[fieldName]}</p>
    ) : null;
  };

   return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 rounded-md containerrr">
      <div className="max-w-3xl mx-auto">
      <button 
          onClick={() => navigate(-1)} 
          className="flex items-center  hover:text-gray-900 transition-colors mb-6 group"
        >
          <FaChevronLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
          <span className="ml-2 text-lg font-medium">Back</span>
        </button>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden containerrrr">
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-bold ">Create New Event</h1>
            <p className=" mt-1">Fill in the details for your new event</p>
          </div>
          
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6 containerrrr">
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900">Basic Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                        touched.name && validationErrors.name 
                          ? 'border-red-500 focus:ring-red-500' 
                          : 'border-gray-300 focus:ring-blue-500'
                      } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                    />
                    {renderFieldError('name')}
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Type</label>
                    <input
                      type="text"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                        touched.name && validationErrors.name 
                          ? 'border-red-500 focus:ring-red-500' 
                          : 'border-gray-300 focus:ring-blue-500'
                      } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                    />
                    {renderFieldError('type')}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Theme</label>
                    <input
                      type="text"
                      name="theme"
                      value={formData.theme}
                      onChange={handleChange}
                      className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                        touched.name && validationErrors.name 
                          ? 'border-red-500 focus:ring-red-500' 
                          : 'border-gray-300 focus:ring-blue-500'
                      } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                    />
                    {renderFieldError('theme')}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <DatePicker
                      selected={formData.date}
                      onChange={handleDateChange}
                      className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                        touched.name && validationErrors.name 
                          ? 'border-red-500 focus:ring-red-500' 
                          : 'border-gray-300 focus:ring-blue-500'
                      } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                    />
                    {renderFieldError('date')}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Host</label>
                    <input
                      type="text"
                      name="host"
                      value={formData.host}
                      onChange={handleChange}
                      className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                        touched.name && validationErrors.name 
                          ? 'border-red-500 focus:ring-red-500' 
                          : 'border-gray-300 focus:ring-blue-500'
                      } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                    />
                    {renderFieldError('host')}
                  </div>
                </div>
              </div>

              {/* Location Section */}
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900">Location Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Region</label>
                    <select
                      name="regionId"
                      value={formData?.regionId}
                      onChange={handleSelectChange('regionId')}
                      className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                    >
                      <option value="">Select a region</option>
                      {regionOptions.map((region: any) => (
                        <option key={region.id} value={region.id}>{region.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Sub-Region</label>
                    <select
                      name="subRegionId"
                      value={formData?.subRegionId}
                      onChange={handleSelectChange('subRegionId')}
                      className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                    >
                      <option value="">Select a sub-region</option>
                      {subRegionOptions.map((subRegion: any) => (
                        <option key={subRegion.id} value={subRegion.id}>{subRegion.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Nation</label>
                    <select
                      name="nationality"
                      value={formData?.nationId}
                      onChange={handleSelectChange('nationId')}
                      className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                    >
                      <option value="">Select a nationality</option>
                      {nationalityOptions.map((nationality: any) => (
                        <option key={nationality.id} value={nationality.id}>{nationality.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                    />
                  </div>
                </div>
              </div>

              {/* Media Section */}
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900">Media</h2>
                
                {/* Cover Image */}
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-gray-700">Cover Image</label>
                  <div className="flex items-center space-x-6">
                    <div className="flex-1">
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors cursor-pointer">
                        <div className="space-y-1 text-center">
                          <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
                          {!imageLoadingCover? (
                      <> 
                       <div className="flex text-sm text-gray-600">
                            <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                              <span>Upload a file</span>
                              <input
                                type="file"
                                name="coverImage"
                                className="sr-only"
                                onChange={handleImageChange}
                              />
                            </label>
                          </div>
                          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </>
                      ):(
                          <FadeLoader color="#367fd6"  />
                                             )}
                      </div>
                    </div>
                    {imageURL && (
                      <img
                        src={`https://fm.upfafrica.org${imageURL}`}
                        alt="Cover preview"
                        className="h-32 w-32 object-cover rounded-lg shadow-md"
                      />
                    )}
                  </div>
                </div>
                </div>
                {/* Gallery */}
                <div className="space-y-4">
                  <label className="block text-sm font-medium text-gray-700">Gallery Images (Max 9)</label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors cursor-pointer">
                    <div className="space-y-1 text-center">
                      <FaImages className="mx-auto h-12 w-12 text-gray-400" />
                    {!imageLoadingGallery? (
                      <>
                       <div className="flex text-sm text-gray-600">
                       <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                         <span>Upload files</span>
                         <input
                           type="file"
                           multiple
                           className="sr-only"
                           onChange={handleGalleryChange}
                         />
                       </label>
                     </div>
                     <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB each</p> 
                     </>
                    ):(
 <FadeLoader color="#367fd6" className='w-96 h-96' />
                    )} 
                      </div>
                      </div>
                  {File?.length > 9 && (
                    <div className="text-red-600 text-sm font-medium">
                      You can only upload up to 9 images!
                    </div>
                  )}

                  {gallery.length > 0 && (
                    <div className="grid grid-cols-3 gap-4 mt-4">
                      {gallery.map((image, index) => (
                        <img
                          key={index}
                          src={`https://fm.upfafrica.org${image}`}
                          alt={`Gallery image ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg shadow-md"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 rounded-md border ${
                    touched.name && validationErrors.name 
                      ? 'border-red-500 focus:ring-red-500' 
                      : 'border-gray-300 focus:ring-blue-500'
                  } shadow-sm focus:border-blue-500 focus:ring-1 transition-colors`}
                />
                {renderFieldError('description')}
              </div>


              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`${
                    isSubmitting ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                  } text-white px-6 py-3 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors flex items-center space-x-2`}
                >
                  {isSubmitting && <FadeLoader color="#ffffff" />}
                  <span>{isSubmitting ? 'Creating Event...' : 'Create Event'}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
