import React from 'react';
import Chart from 'react-google-charts';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface MyChartComponentProps {
    subregionCounts: { [key: number]: number };
    darkMode: boolean;
}
const MyChartComponent: React.FC<MyChartComponentProps> = ({ subregionCounts, darkMode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleChartClick = (e:any) => {
        const chart = e.chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;

        const selectedItem = selection[0];
        const subRegion = e.chartWrapper.getDataTable().getValue(selectedItem.row, 0);
        navigateToPage(subRegion);
    };

    const navigateToPage = (subRegion:any) => {
        switch (subRegion) {
            case 'SR West':
                navigate('/sr-west');
                break;
            case 'SR East':
                navigate('/sr-east');
                break;
            case 'SR North':
                navigate('/sr-north');
                break;
            case 'SR South':
                navigate('/sr-south');
                break;
            case 'SR Central':
                navigate('/sr-central');
                break;
            case 'Korea':
                navigate('/korea');
                break;
            case 'USA':
                navigate('/usa');
                break;
            default:
                break;
        }
    };

    return (
        <Chart
            chartType="ColumnChart"
            data={[
                ['Sub-Region', 'Count', { role: "style" }],
                [t('SR West'), subregionCounts[15], '#b87333'],
                [t('SR East'), subregionCounts[12], "#3366cc"],
                [t('SR North'), subregionCounts[13], "#dc3912"],
                [t('SR South'), subregionCounts[14], "#ff9900"],
                [t('SR Central'), subregionCounts[11], "#109618"],
                // ['Korea', subregionCounts[9], "#990099"],
                // ['USA', subregionCounts[10], "#0099c6"],
            ]}
            options={{
                hAxis: {
                    title: '',
                    textStyle: { color: darkMode ? '#fff' : '#000' }
                },
                vAxis: {
                    title: '',
                    textStyle: { color: darkMode ? '#fff' : '#000' }
                },
                legend: 'none',
                backgroundColor: darkMode ? '#333' : 'white',
                chartArea: { color: darkMode ? "#ffffff" : "",cursor:'pointer' },
            }}
            
            width="100%"
            height="400px"
            chartEvents={[
                {
                    eventName: 'select',
                    callback: handleChartClick
                }
            ]}
            
        />
    );
};

export default MyChartComponent;
