import React, { useEffect, useState } from "react";
import { Empty, Modal, notification, Input, Pagination,Select, Button } from 'antd';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useDebounce } from 'use-debounce';
import { Link, useNavigate } from "react-router-dom";
import { TiCancel } from "react-icons/ti";
const Option=Select;
// Types
interface User {
    id: number;
    name: string;
    religion:string;
    position?: string;
    upfPosition?: string;
    email:string;
    nationality: number;
    userType: number;
    subRegionId: number;
    regionId: number;
    leadershipStatus?: boolean;
    ambassadorStatus?: boolean;
}

interface Nation {
    id: number;
    name: string;
}

interface AmbassadorProps {
    users: User[];
    deleteUserProp: (id: number) => Promise<void>;
}

const Ambassador: React.FC<AmbassadorProps> = ({ users, deleteUserProp }) => {
    // State
    const [usersData, setUsersData] = useState<User[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [filterValue, setFilterValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [massDelete, setMassDelete] = useState<number[]>([]);
    const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
    const [displayDelete, setDisplayDelete] = useState(false);
    const [debouncedFilterValue] = useDebounce(filterValue, 300);

    const nationData = localStorage.getItem('nations');
    const nationList: Nation[] = nationData ? JSON.parse(nationData) : [];
    const storedUser = localStorage.getItem('user');
    const currentUser: User | null = storedUser ? JSON.parse(storedUser) : null;
    const subRegionData = localStorage.getItem('subRegions');
    const subList = subRegionData ? JSON.parse(subRegionData) : [];
    const regionData = localStorage.getItem('regions');
    const regionList = regionData ? JSON.parse(regionData) : [];
    
    // Hooks
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();

    // Filter and sort users based on various criteria
    const filterUsers = (users: User[]) => {
        return users.filter((item: User) => {
            const userTypeCheck = item.userType === 8 || item.userType === 5;
            const subRegionCheck = item.subRegionId === currentUser?.subRegionId;
            const nationalityCheck = item.nationality === currentUser?.nationality;
            const statusCheck = statusFilter === 'leadership' 
                ? item.leadershipStatus 
                : statusFilter === 'ambassador' 
                    ? item.ambassadorStatus 
                    : true;
            const rankCheck = item.ambassadorStatus ;
            
            const searchMatch = (
                (item.name?.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                (item.position?.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                (getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
            );

            if (!currentUser) return false;

            switch (currentUser.userType) {
                case 8:
                    return rankCheck && searchMatch;
                case 5:
                case 6:
                case 3:
                    return nationalityCheck && searchMatch;
                case 2:
                case 7:
                    return subRegionCheck && searchMatch;
                default:
                    return false;
            }
        });
    };

    // Effects
    useEffect(() => {
        if (Array.isArray(users)) {
            setUsersData(filterUsers(users));
        } else {
            console.error('Invalid user data format:', users);
        }
    }, [debouncedFilterValue, statusFilter, users]);

    // Utility functions
    const getNationNameById = (id: number): string => {
        if (!id || !Array.isArray(nationList)) return '';
        const nation = nationList.find(n => n.id === id);
        return nation ? nation.name : '';
    };
    const getSubNameById = (id: any) => {
        if (!id || !Array.isArray(subList) || subList.length === 0) {
            return '';
        }
        const Sub = subList.find(n => n.id === id);
        return Sub ? Sub.name : '';
      };
      const getRegionNameById = (id: any) => {
        if (!id || !Array.isArray(regionList) || regionList.length === 0) {
            return '';
        }
        const Region= regionList.find(n => n.id === id);
        return Region ? Region.name : '';
      };
    // Download functionality
    const downloadCSV = () => {
        if (usersData.length === 0) {
            notification.warning({
                message: 'No Data',
                description: 'There is no data to download.',
                duration: 3,
            });
            return;
        }

        const headers = ['No.', 'Ambassador', 'Position','Religion','UpfPosition', 'Nationality', 'Sub-Region', 'Region','Email'];
        const csvContent = [
            headers.join(','),
            ...usersData.map((user, index) => {
                // Safely handle potentially null values
                const name = (user.name || '').replace(/,/g, ';');
                const position = ((user.position  || '')).replace(/,/g, ';');
                const religion = ((user.religion  || '')).replace(/,/g, ';');
                const UpfPosition = (( user.upfPosition || '')).replace(/,/g, ';');
                const nationality = (getNationNameById(user.nationality) || '').replace(/,/g, ';');
                const subRegion = (getSubNameById(user.subRegionId) || '').replace(/,/g, ';');
                const region = (getRegionNameById(user.regionId) || '').replace(/,/g, ';');
                const email=(user.email || '').replace(/,/g, ';');
                return [
                    index + 1,
                    name,
                    position,
                    religion,
                    UpfPosition,
                    nationality,
                    subRegion,
                    region,
                    email
                ].join(',');
            })
        ].join('\n');

        try {
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Ambassador_${new Date().toISOString().split('T')[0]}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            notification.success({
                message: 'Success',
                description: 'CSV file downloaded successfully',
                duration: 3,
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to download CSV file',
                duration: 3,
            });
            console.error('CSV download error:', error);
        }
    };
    const handleDelete = async () => {
        if (userIdToDelete) {
            try {
                await deleteUserProp(userIdToDelete);
                setIsModalVisible(false);
                notification.success({
                    message: 'Success',
                    description: 'User deleted successfully',
                });
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete user',
                });
            }
        }
    };

    const handleMassDelete = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch('https://api.upfafrica.org/admin/user/deleteMany', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ids: massDelete })
            });

            if (!response.ok) throw new Error('Failed to delete users');

            notification.success({
                message: 'Success',
                description: 'Users deleted successfully',
            });

            setMassDelete([]);
            setIsMassDeleteModalVisible(false);
            setDisplayDelete(false);
            setUsersData(prev => prev.filter(user => !massDelete.includes(user.id)));
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete users',
            });
            console.log("massDelete",error)
        }
    };

    const handleCheckboxChange = (id: number) => {
        setMassDelete(prev => 
            prev.includes(id) 
                ? prev.filter(userId => userId !== id)
                : [...prev, id]
        );
    };

       // Pagination
       const [itemsPerPage, setItemsPerPage] = useState<number>(() => {
        const largeScreen = window.matchMedia("(min-width: 720px)").matches;
        return largeScreen ? 50 : 10;
    });

    // Update the function to handle page size change
    const handlePageSizeChange = (value: number) => {
        setItemsPerPage(value);
        // Reset to first page when changing page size
        setCurrentPage(1);
    };

    // Adjust the pagination data slicing
    const paginatedData = usersData.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );
    const handlePageChange=(page:number)=>{
        setCurrentPage(page)
      }

    return (
        <div className="containerr rounded-lg overflow-hidden w-[95%] shadow-lg bg-white flex ml-5 mt-8">
            <div className="container mx-auto p-8">
                <div className="px-6 py-4 w-full">
                    {/* Header Section */}
                    <div className="flex flex-row justify-between mb-7">
                        <h1 className="font-bold text-xl">Ambassador</h1>
                        
                        <div className="flex gap-3">
                            <Button
                                onClick={downloadCSV}
                                icon={<FontAwesomeIcon icon={faDownload} />}
                                className={`bg-green-500 text-white hover:bg-green-600 ${
                                    darkMode ? 'bg-zinc-700 hover:bg-zinc-600' : ''
                                }`}
                            >
                                Export CSV
                            </Button>

                            <Button
                                onClick={() => navigate("/Ambassadorinput")}
                                className={`bg-slate-300 ${
                                    darkMode ? 'border text-white border-blue-500 bg-zinc-800' : ''
                                }`}
                            >
                                + Add Ambassador
                            </Button>

                            <Input
                                placeholder="Search Ambassador..."
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)}
                                style={{ width: 300 }}
                                className="containerrr"
                            />
                        </div>
                    </div>

                    {/* Mass Delete Controls */}
                    {displayDelete && (
                        <div className="mb-4 flex gap-3">
                            <Button
                                danger
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                onClick={() => setIsMassDeleteModalVisible(true)}
                            />
                            <Button
                                icon={<TiCancel className="w-6 h-6" />}
                                onClick={() => setDisplayDelete(false)}
                            />
                        </div>
                    )}

                    {/* Table */}
                    <table className="w-full">
                        <thead className="text-left">
                            <tr>
                                <th className="py-2">No.</th>
                                <th className="py-2 px-3">Contact</th>
                                <th className="py-2">Position</th>
                                <th className="py-2">Nationality</th>
                                {(currentUser?.userType === 8 || currentUser?.userType === 4 || 
                                  currentUser?.userType === 7 || currentUser?.userType === 6) && 
                                    <th className="py-2">Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((user, index) => (
                                    <tr key={user.id} className={`hover:bg-gray-400 hover:px-10 hover:rounded-lg  ${darkMode?'hover:text-blue-800':''} `}>
                                        <td className="py-2 px-1">
                                            {(currentPage - 1) * itemsPerPage + index + 1}
                                        </td>
                                        <td className="py-2 px-3">
                                            <Link to={`/individual/${user.id}`} >
                                                {user.name}
                                            </Link>
                                        </td>
                                        <td className="py-2">{user.position || user.upfPosition}</td>
                                        <td className="py-2">{getNationNameById(user.nationality)}</td>
                                        {(currentUser?.userType === 8 || currentUser?.userType === 4 || 
                                          currentUser?.userType === 7 || currentUser?.userType === 6) && (
                                            <td className="py-2 flex gap-3">
                                                {!displayDelete && (
                                                    <>
                                                        <Button
                                                            icon={<FontAwesomeIcon icon={faTrash} />}
                                                            danger
                                                            onClick={() => {
                                                                setUserIdToDelete(user.id);
                                                                setIsModalVisible(true);
                                                            }}
                                                        />
                                                        <Button
                                                            icon={<CiEdit size={20} />}
                                                            onClick={() => navigate(`/editAmbassador/${user.id}`)}
                                                        />
                                                    </>
                                                )}
                                                {displayDelete && (
                                                    <input
                                                        type="checkbox"
                                                        checked={massDelete.includes(user.id)}
                                                        onChange={() => handleCheckboxChange(user.id)}
                                                    />
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center py-4">
                                        <Empty description="No Data Found" />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div className="flex justify-between items-center mt-4">
                    <Select
                        value={itemsPerPage}
                        style={{ width: 120 }}
                        onChange={handlePageSizeChange}
                    >
                        <Option value={10}>10 per page</Option>
                        <Option value={25}>25 per page</Option>
                        <Option value={50}>50 per page</Option>
                        <Option value={100}>100 per page</Option>
                    </Select>
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={usersData.length}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        style={{ textAlign: 'center' }}
                        itemRender={(page, type, originalElement) => {
                            if (type === 'page') {
                                return (
                                    <a
                                       className="paginateNumber "
                                    >
                                        {page}
                                    </a>
                                );
                            }
                            return originalElement;
                        }}
                    />
                </div>
                </div>
            </div>
            <Modal
                title="Delete User"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="many"
                        type="primary"
                        onClick={() => {
                            setMassDelete([]);
                            setIsModalVisible(false);
                            setDisplayDelete(true);
                        }}
                        className="bg-blue-500"
                    >
                        Delete Multiple
                    </Button>,
                    <Button key="delete" danger onClick={handleDelete}>
                        Delete
                    </Button>,
                ]}
            >
                <p>Are you sure you want to delete this user?</p>
            </Modal>

            {/* Mass Delete Modal */}
            <Modal
                title="Mass Delete Users"
                open={isMassDeleteModalVisible}
                onCancel={() => {
                    setIsMassDeleteModalVisible(false);
                    setDisplayDelete(false);
                }}
                onOk={handleMassDelete}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete {massDelete.length} selected users?</p>
            </Modal>
        </div>
    );
};

export default Ambassador;