import React, { useState, ChangeEvent, FormEvent,useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import {  useNavigate } from "react-router-dom";
import { FaChevronLeft, FaUser, FaGlobe, FaBuilding, FaSave, FaPhone, FaEnvelope, FaBook, FaUserCircle, FaFlag, FaUsers, FaImage, FaMapMarker, FaTwitter, FaFacebook, FaInstagram, FaLinkedin,FaUpload } from "react-icons/fa";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import ReligionSelect from '../../globals/ReligionSelect';
import axios from "axios";
import {notification} from 'antd';
import { FadeLoader } from 'react-spinners';
interface FormErrors {
  name?: string;
  email?: string;
  nationality?: string;
  region?: string;
  subRegion?: string;
  [key: string]: string | undefined;
}
interface AmbassadorFormData {
  name: string;
  religion: string;
  gender: string;
  email: string;
  upfPosition: string;
  honorifics: string;
  username: string;
  primaryTag: number;
 otherTags: string;
  nationality:number;
  regionId: number,
  subRegionId: number,
  userType: number,
   mobileNo: string,
   leadershipStatus: boolean;
  ambassadorStatus: boolean;
  biography:string;
  picture: string;
  pointEmail:string;
  city: string;
  street:string;
  twitterID: string;
  linkedInID:string;
  instagramID: string;
  facebookID:string;
  organization: string;
}
interface Nation {
  id: number;
  name: string;
}
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
const Ambassador: React.FC = () => {
  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionList = subRegionData ? JSON.parse(subRegionData) : [];
  const[pageNumber,setPageNumber]=useState<number>(1)
  const [imageURL, setImageURL] = useState<string>('');
  const [fileURL, setFileURL] = useState<string>('');
  const storedUser = localStorage.getItem('user');
  const { t} = useTranslation();
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [formData, setFormData] = useState<AmbassadorFormData>({
    name: '',
    religion: '',
    email: '',
    gender: '',
    upfPosition: '',
    honorifics: '',
    username: '',
    primaryTag: 1,
    nationality: 1,
    subRegionId: 1,
    regionId: 1,
    userType: 1,
    biography:'',
    otherTags: '',
    mobileNo: '',
    picture: '',
    pointEmail:'',
    city: '',
    street:'',
    twitterID: '',
    
linkedInID:'',
    instagramID: '',
    facebookID:'',
    organization: '',
    leadershipStatus: false,
    ambassadorStatus: true,
  });
  const regionData =localStorage.getItem('regions');
  const [activeTab, setActiveTab] = useState('text');
  const[fileLoading,setFileLoading]=useState<boolean>(false)
  const [biographyText, setBiographyText] = useState('');
  const [biographyType, setBiographyType] = useState('text');
  const[imageLoading,setImageLoading]=useState<boolean>(false)
  const regionList= regionData ? JSON.parse(regionData) : [];
  const { darkMode } = useDarkMode();
  const [emailError, setEmailError] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const validateField = (name: string, value: any) => {
    switch (name) {
      case 'name':
        return value.trim() ? '' : 'Name is required';
      case 'email':
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value) 
          ? '' 
          : 'Please enter a valid email address';
      case 'nationality':
        return value !== 1 ? '' : 'Please select a nationality';
      case 'region':
        return value !== 1 ? '' : 'Please select a region';
      case 'subRegion':
        return value !== 1 ? '' : 'Please select a sub-region';
      default:
        return '';
    }
  };
  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;

    // Validate required fields
    ['name', 'email', 'nationality', 'regionId', 'subRegionId', 'userType'].forEach(field => {
      const error = validateField(field, formData[field as keyof AmbassadorFormData]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };
  console.log('checking env:', {
    all: process.env,
    strapi: process.env.REACT_APP_STRAPI_API_CREATE,
    node_env: process.env.NODE_ENV
  });
  const handleBiographyTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
  
     setBiographyText(e.target.value)
  };
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      picture: imageURL,
      biography: biographyText,
      
linkedInID: fileURL,
    }));
  }, [imageURL, fileURL, biographyText, activeTab]);
 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);
  const handleSelectChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: parseInt(value, 10),
    });
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  const handleSelectGenderChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('files', file);
    setImageLoading(true);

    try {
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`,
          },
        }
      );

      if (Array.isArray(response.data) && response.data.length > 0) {
        const url = response.data[response.data.length - 1].url;
        setImageURL(url);
        setFormData(prev => ({ ...prev, coverImage: url }));
        notification.success({
          message: 'Success',
          description: 'Image uploaded successfully',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to upload image',
      });
    } finally {
      setImageLoading(false);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    setFileLoading(true);
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);  
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`, // Ensure token is correct
          },
        }
      );
  
      // Log the response to check the format
      console.log("Response data:", response.data);
  
      if (response.status !== 200 || !response.data) {
        throw new Error('Failed to upload file');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastFileUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setFileURL(lastFileUrl);
        notification.success({
          message: 'Success',
          description: 'File uploaded successfully',
        });
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to upload file',
      });
      console.error("Error uploading file:", error);
    } finally {
      setFileLoading(false);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : name === 'nationality' ||name === 'userType' ||name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  const handlePhoneChange = (value:any) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNo: value,
    }));
  };
  const navigate = useNavigate();
  // const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (typeof reader.result === 'string') {
  //         setFormData({
  //           ...formData,
  //           picture: reader.result,
  //         });
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  
  if (!validateForm()) {
    notification.error({
      message: 'Validation Error',
      description: 'Please fill in all required fields correctly.',
    });
    return;
  }
  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return;
  }

  try {
    console.log('Token:', token);
    console.log('Form Data:', formData);

    const response = await fetch('https://api.upfafrica.org/admin/user/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    // Attempt to parse the response
    let responseData;
    try {
      responseData = await response.json();
    } catch (jsonError) {
      console.error('Failed to parse response JSON', jsonError);
      return;
    }

    if (!response.ok) {
      console.error('Failed to submit form', responseData);
      alert(`Failed to submit form: ${responseData.message || 'Unknown error'}`);
    } else {
      notification.success({
              message: 'Success',
              description: 'Ambassador created successfully',
            });
      console.log("Ambassadors",responseData);
      navigate('/Ambassador-info');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An unexpected error occurred. Please try again later.');
  }
};

  const back=()=>{
    navigate(-1)
   }
   const backPage=()=>{
    navigate(pageNumber-1)
   }

  

  return (
   <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 containerrr">
    <div className="max-w-4xl mx-auto ">
      {/* Back Button */}
      
       <button onClick={back} className="flex items-center text-gray-600 hover: mb-6 transition-colors">
        <FaChevronLeft className="w-5 h-5" />
        <span className="ml-2 text-lg font-medium">{t('Back')}</span>
       </button>
      

      {/* Form Header */}
      <div className="mb-8 text-center">
       <h1 className="text-3xl font-bold ">{t('Ambassador Registration')}</h1>
       <p className="mt-2 ">{t('Complete the form below to register a new Ambassador')}</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-lg rounded-lg p-6 containerrrr">
       {/* Progress Steps */}
     

        <div className="space-y-8 containerrrr">
          {/* Personal Information Section */}
          <div className="bg-gray-50 p-6 rounded-lg containerrrr">
           <h2 className="text-xl font-semibold  mb-6 flex items-center">
            <FaUser className="mr-2" /> {t('Personal Information')}
           </h2>
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name Fields */}
            <div className="space-y-4">
              <label className="block">
               <span className="text-sm font-medium flex items-center">
                <FaUserCircle className="mr-2" /> {t('Honorifics')}
               </span>
               <input
                type="text"
                name="honorifics"
                value={formData.honorifics}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder={t('e.g., Dr., Mr., Mrs.')}
               />
              </label>

              <label className="block">
               <span className="text-sm font-medium flex items-center">
                <FaUser className="mr-2" /> {t('Full Name')} *
               </span>
               <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder={t('Enter full name')}
               />
              {errors.name && <p className="mt-1 text-sm text-red-600">{t(errors.name)}</p>}
              </label>
              <div className="space-y-4">
            <label className="block text-sm font-medium ">{t('Profile Image')}</label>
            <div className="flex items-center space-x-6">
              <div className="flex-1">
               <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors cursor-pointer">
                <div className="space-y-1 text-center">
                  <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
                  {!imageLoading? (
               <> 
                <div className="flex text-sm text-gray-600">
                   <label className="relative cursor-pointer rounded-md p-2 font-medium text-blue-600 hover:text-blue-500">
                    <span>{t('Upload a file')}</span>
                    <input
                      type="file"
                      name="coverImage"
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                   </label>
                  </div>
                  <p className="text-xs ">{t('PNG, JPG, GIF up to 10MB')}</p>
                </>
               ):(
                  <FadeLoader color="#367fd6"  />
                              )}
               </div>
              </div>
              {imageURL && (
               <img
                src={`https://fm.upfafrica.org${imageURL}`}
                alt={t('Cover preview')}
                className="h-32 w-32 object-cover rounded-lg shadow-md"
               />
              )}
            </div>
           </div>
           </div>
           <div className="block mb-2">
          <label className="block font-medium mb-2">{t('Biography')}:</label>
          <div className="flex mb-2 border-b">
           <button
            type="button"
            className={`px-4 py-2 ${biographyType === 'text' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
            onClick={() => setBiographyType('text')}
           >
            {t('Write Text')}
           </button>
           <button
            type="button"
            className={`px-4 py-2 ${biographyType === 'file' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
            onClick={() => setBiographyType('file')}
           >
            {t('Upload File')}
           </button>
          </div>
          {biographyType === 'text' ? (
          <textarea
            className="w-full p-2 border rounded-md mt-1"
            rows={4}
            value={formData.biography}
            onChange={handleBiographyTextChange}
            placeholder={t('Enter your biography...')}
          />
         ) : (
          !fileLoading ? (
            <div>
             <input
              className="w-full p-2 border rounded-md mt-1"
              placeholder={t('PDF format only')}
              type="file"
              onChange={handleFileChange}
             />
             {fileURL ? (
              <div className="mt-2">
                <a 
                 href={`https://fm.upfafrica.org${fileURL}`} 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="text-blue-500 hover:underline"
                >
                 {t('View Uploaded Biography')}
                </a>
              </div>
             ) : (
              <p className="mt-1 text-sm text-red-600">{t('No file uploaded')}</p>
             )}
            </div>
          ) : (
            <FadeLoader color="#367fd6" />
          )
         )}
        </div>
            </div>

            {/* Contact Fields */}
            <div className="space-y-4">
              <label className="block">
               <span className="text-sm font-medium flex items-center">
                <FaPhone className="mr-2" /> {t('Mobile Number')}
               </span>
               <div className="mt-1">
                <PhoneInput
                  country={'ke'}
                  value={formData.mobileNo}
                  onChange={handlePhoneChange}
                  inputClass={`w-full p-2 border rounded-md p-2 ${darkMode ? 'dark:bg-gray-800 dark:text-white' : ''}`}
                  buttonClass={`border rounded-l-md ${darkMode ? 'dark:bg-gray-700' : ''}`}
                  dropdownClass={`${darkMode ? 'dark:bg-gray-800 dark:text-white' : ''}`}
                />
               </div>
              </label>

              <label className="block">
               <span className="text-sm font-medium flex items-center">
                <FaEnvelope className="mr-2" /> {t('Email Address')} *
               </span>
               <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md p-2 p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder={t('email@example.com')}
               />
               {errors.email && <p className="mt-1 text-sm text-red-600">{t(errors.email)}</p>}
              </label>
            </div>

            {/* Religion and Gender */}
            <div className="space-y-4">
              <label className="block">
               <span className="text-sm font-medium ">{t('Religion')}</span>
               <ReligionSelect 
                formData={formData} 
                setFormData={setFormData}
                // className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
               />
              </label>
            </div>

            <div className="space-y-4">
              <label className="block">
               <span className="text-sm font-medium ">{t('Gender')}</span>
               <select
                name="gender"
                value={formData.gender}
                onChange={handleSelectGenderChange('gender')}
                className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
               >
                <option value="">{t('Select Gender')}</option>
                <option value="Male">{t('Male')}</option>
                <option value="Female">{t('Female')}</option>
                <option value="Other">{t('Other')}</option>
               </select>
              </label>
            </div>
           </div>
          </div>

          {/* Position & Role Section */}
          <div className="bg-gray-50 p-6 rounded-lg containerrrr">
           <h2 className="text-xl font-semibold  mb-6 flex items-center">
            <FaBuilding className="mr-2" /> {t('Position & Role')}
           </h2>
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <label className="block">
              <span className="text-sm font-medium ">{t('UPF Position')}</span>
              <input
               type="text"
               name="upfPosition"
               value={formData.upfPosition}
               onChange={handleChange}
               className="mt-1 block w-full  rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
               placeholder={t('Enter UPF position')}
              />
            </label>

            <label className="block">
              <span className="text-sm font-medium ">{t('Username')}</span>
              <input
               type="text"
               name="username"
               value={formData.username}
               onChange={handleChange}
               className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
               placeholder={t('Choose a username')}
              />
            </label>

            <label className="block">
              <span className="text-sm font-medium ">{t('User Type')}</span>
              <select
               name="userType"
               value={formData.userType}
               onChange={handleSelectChange('userType')}
               className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
               <option value={0}>{t('Select User Type')}</option>
               <option value={1}>{t('Regional Coordinator')}</option>
               <option value={2}>{t('SR Ambassador')}</option>
               <option value={3}>{t('National Ambassador')}</option>
               <option value={4}>{t('Association Chairman/President')}</option>
               <option value={5}>{t('National Coordinator')}</option>
               <option value={6}>{t('National SG')}</option>
               <option value={7}>{t('SR Admin')}</option>
               <option value={8}>{t('Super Admin')}</option>
               <option value={9}>{t('System User')}</option>
               <option value={10}>{t('User')}</option>
              </select>
            </label>
           </div>

           <div className="mt-4">
            <label className="inline-flex items-center">
              <input
               type="checkbox"
               name="leadershipStatus"
               checked={formData.leadershipStatus}
               onChange={handleChange}
               className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm ">{t('Leadership Status')}</span>
            </label>
           </div>
          </div>

          {/* Location Section */}
          <div className="bg-gray-50 p-6 rounded-lg containerrrr">
           <h2 className="text-xl font-semibold  mb-6 flex items-center">
            <FaGlobe className="mr-2" /> {t('Location')}
           </h2>
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            
            <label className="block">
              <span className="text-sm font-medium ">{t('Region')} *</span>
              <select
               name="regionId"
               value={formData.regionId}
               onChange={handleSelectChange('regionId')}
               className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
               <option value={0}>{t('Select Region')}</option>
               {regionList.map((region: any) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
               ))}
              </select>
              {errors.region && <p className="mt-1 text-sm text-red-600">{t(errors.region)}</p>}
            </label>

            <label className="block">
              <span className="text-sm font-medium ">{t('Sub-Region')} *</span>
              <select
               name="subRegionId" 
               value={formData.subRegionId}
               onChange={handleSelectChange('subRegionId')}
               className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
               <option value={0}>{t('Select Sub-Region')}</option>
               {subRegionList.map((subRegion: any) => (
                <option key={subRegion.id} value={subRegion.id}>
                  {subRegion.name}
                </option>
               ))}
              </select>
              {errors.subRegion && <p className="mt-1 text-sm text-red-600">{t(errors.subRegion)}</p>}
            </label>
            <label className="block">
              <span className="text-sm font-medium ">{t('Nationality')} *</span>
              <select
               name="nationality"
               value={formData.nationality}
               onChange={handleSelectChange('nationality')}
               className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
               <option value={0}>{t('Select Nationality')}</option>
               {nationList.map((nation: any) => (
                <option key={nation.id} value={nation.id}>
                  {nation.name}
                </option>
               ))}
              </select>
              {errors.nationality && <p className="mt-1 text-sm text-red-600">{t(errors.nationality)}</p>}
            </label>

           </div>
          </div>
          <div className="flex justify-end mt-8">
           <button
            type="submit"
            className="px-6 py-3 bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
           >
            {t('Submit')}
           </button>
          </div>
        </div>
        </form>
        </div>
        </div>
       );
};

export default Ambassador;
