import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Empty, Modal,  notification, Input, Button } from 'antd';
import image1 from '../images/1.png';
import image2 from '../images/2.png';
import image3 from '../images/3.png';
import image4 from '../images/4.png';
import image5 from '../images/5.png';
import MyChartComponent from './barGraph'
import { Users, Award, ContactIcon, UserCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useDebounce } from 'use-debounce';
import { useNavigate } from "react-router-dom";
import { Select ,Pagination} from 'antd';
import { FadeLoader } from "react-spinners";
import { TiCancel } from "react-icons/ti";
const { Option } = Select;

interface User {
    userType: number;
    subRegionId:number;
    regionId:number;
    nationality:number;
  }
interface UserListProps {
    users: User[];
    deleteUserProp:any;
}
const MainPage: React.FC<UserListProps> = ({users,deleteUserProp}) => {
   

    const [subregionCounts, setSubregionCounts] = useState<any>({
        11: 0, // SR Central
        12: 0, // SR East
        13: 0, // SR North
        14: 0, // SR South
        15: 0, // SR West
      });
    const [usersData, setUsersData] = useState<any[]>([]);
    const [massDelete, setMassDelete] = useState<any[]>([]);
    const [massFilter, setMassFilter] = useState<any[]>([]);
    const [subFilter, setSubFilter] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
    const nationData = localStorage.getItem('nations');
    const nationList = nationData ? JSON.parse(nationData) : [];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [displayDelete, setDisplayDelete] = useState(false);

    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); 
    const subRegions= localStorage.getItem('subRegions');
    const subRegionList = subRegions ? JSON.parse(subRegions) : [];
    const [issubRegionDropdownVisible, setIssubRegionDropdownVisible] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [debouncedFilterValue] = useDebounce(filterValue, 300);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();
    const [sortKey, setSortKey] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); 
    console.log(massFilter)
    console.log(subFilter)
    useEffect(() => {
        if (statusFilter === 'Nation') {
          setIsDropdownVisible(true);
        } else {
          setIsDropdownVisible(false);
        }
      }, [statusFilter]);
      useEffect(() => {
        if (statusFilter === 'Sub-Region') {
          setIssubRegionDropdownVisible(true);
        } else {
          setIssubRegionDropdownVisible(false);
        }
      }, [statusFilter]);
      const [itemsPerPage, setItemsPerPage] = useState<number>(() => {
        const largeScreen = window.matchMedia("(min-width: 720px)").matches;
        return largeScreen ? 50 : 10;
    });

    // Update the function to handle page size change
    const handlePageSizeChange = (value: number) => {
        setItemsPerPage(value);
        // Reset to first page when changing page size
        setCurrentPage(1);
    };

    // Adjust the pagination data slicing
    const paginatedData = usersData.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );
   
    
    
      const getNationNameById = (id: any) => {
        const nation = nationList.find((n:any)=> n.id === id);
        return nation ? nation.name : '';
    };
    const numberLeadership = users.filter((user: any) => user.leadershipStatus).length;
    const numberAmbassador = users.filter((user: any) => user.ambassadorStatus).length;
    const numberContacts = users.filter((user: any) => !user.leadershipStatus && !user.ambassadorStatus).length;
    const totalUsers = users.length;
      const GetAllUsers= (page: number) => {
       
            const subregionCounts: Record<number, number> = {
                11: 0, // SRCENTRAL
                12: 0, // SREAST
                13: 0, // SRNORTH
                14: 0, // SRSOUTH
                15: 0, // SRWEST
            };
    
            users.forEach((user: any) => {
                const subRegionId = user.subRegionId;
                if (subRegionId in subregionCounts) {
                    subregionCounts[subRegionId]++;
                }
            });
         
            setSubregionCounts(subregionCounts);
    
               
            const filteredUsers = users.filter((item: any) => {
                const userTypeCheck = [5, 8].includes(item?.userType);
                const subRegionCheck = item?.subRegionId === User?.subRegionId;
                const nationalCheck = massFilter.length === 0 || (item?.nationality && massFilter.includes(getNationNameById(item.nationality)));
                const subCheck = subFilter.length === 0 || (item?.subRegionId && subFilter.includes(getSubRegionNameById(item.subRegionId)));
                const nationalityCheck = item?.nationality === User?.nationality;
                const statusCheck = 
                    statusFilter === 'leadership' ? item.leadershipStatus :
                    statusFilter === 'ambassador' ? item.ambassadorStatus :
                    statusFilter === 'Contacts' ? (!item.ambassadorStatus && !item.leadershipStatus) :
                    true;
    
                const nameMatch = item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase());
                const positionMatch = item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase());
                const nationalityMatch = item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase());
                const subRegionMatch = item.subRegionId && getSubRegionNameById(item.subRegionId).toLowerCase().includes(debouncedFilterValue.toLowerCase());
    
                switch (User?.userType) {
                    case 8:
                        return statusCheck && nationalCheck && subCheck && (nameMatch || positionMatch || nationalityMatch || subRegionMatch);
                    case 5:
                    case 6:
                    case 3:
                        return statusCheck && nationalityCheck && (nameMatch || positionMatch || nationalityMatch);
                    case 2:
                    case 7:
                        return statusCheck && subRegionCheck && (nameMatch || positionMatch || nationalityMatch);
                    default:
                        return false;
                }
            });
    
            setUsersData(filteredUsers);
    
    
        
    };
    useEffect(() => {
          GetAllUsers(currentPage);
        
    }, [debouncedFilterValue, statusFilter,massFilter,subFilter]);
    
    const deleteUser = async (id: any) => {
        deleteUserProp(id) 
    };
    const showModal = (id: any) => {
        setUserIdToDelete(id);
        setIsModalVisible(true);
    };
    
    
    const handleEdit = (id: any) => {
        navigate(`/EditAmbassador/${id}`);
    };

    const handleDelete = async () => {
        try {
            await deleteUser(userIdToDelete);
            setIsModalVisible(false);
        } catch (error) {
            // Handle error
        }
    };

const getSubRegionNameById = (id: any) => {
    if (!id || !Array.isArray(subRegionList) ||subRegionList.length === 0) {
        return '';
    }
    const nation = subRegionList.find(n => n.id === id);
    return nation ? nation.name : '';
};

   
    const darkModeClass = darkMode ? 'dark' : '';
    const subRegionData = async () => {
          await GetAllUsers(currentPage); // Pass currentPage here   
      };
  
    useEffect(() => {
        subRegionData()
      const fetchData = async () => {
          await GetAllUsers(currentPage); // Pass currentPage here
      };
      fetchData();
  }, [currentPage]);
  const deleteMassUser = async () => {
    try {
        const apiUrl = `https://api.upfafrica.org/admin/user/deleteMany`;
        const token = sessionStorage.getItem('token');

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                ids: massDelete // Send the array of user IDs to be deleted
            }),
        });

        if (!response.ok) {
            console.error('Request failed:', response.status, response.statusText);
            throw new Error('Request failed.');
        }

        const responseData = await response.json();
        console.log('Users deleted successfully:', responseData);

        notification.open({
            message: 'Users Deleted',
            description: 'The users have been deleted successfully.',
            duration: 3,
        });

        GetAllUsers(currentPage); 

        return responseData;
    } catch (error) {
        console.error('Error:', error);

        notification.error({
            message: 'Error Deleting Users',
            description: 'An error occurred while deleting the users.',
            duration: 3,
        });

        return error;
    }
};
  const showMassDeleteModal = () => {
    setIsMassDeleteModalVisible(true);
};
  const handleMassDelete = async () => {
    try {
        await deleteMassUser();
        setIsMassDeleteModalVisible(false);
    } catch (error) {
        // Handle error if needed
    }
};
  const handleCheckboxChange = (id: any) => {
    setMassDelete((prev) => {
        if (prev.includes(id)) {
            return prev.filter((ambassadorId) => ambassadorId !== id);
        } else {
            return [...prev, id];
        }
    });
};
const handleFilterCheckboxChange = (nationName: string) => {
    if (massFilter.includes(nationName)) {
        setMassFilter(massFilter.filter((name) => name !== nationName));
    } else {
        setMassFilter([...massFilter, nationName]);
    }
};
const handleSubCheckboxChange = (subName: string) => {
    if (subFilter.includes(subName)) {
        setMassFilter(subFilter.filter((name) => name !== subName));
    } else {
        setMassFilter([...subFilter, subName]);
    }
};
  const handleMany=()=>{
    setMassDelete([])
    setIsModalVisible(false);
    setDisplayDelete(true)
}
  const handlePageChange=(page:number)=>{
    setCurrentPage(page)
  }

  const cardsData = [
    { id: 1, imageUrl: image1, text1: subregionCounts[13] || '0', text2: 'SR North', to: '/sr-north' },
    { id: 2, imageUrl: image2, text1: subregionCounts[15] || '0', text2: 'SR West', to: '/sr-west' },
    { id: 3, imageUrl: image3, text1: subregionCounts[11] || '0', text2: 'SR Central', to: '/sr-central' },
    { id: 4, imageUrl: image4, text1: subregionCounts[14] || '0', text2: 'SR South', to: '/sr-south' },
    { id: 5, imageUrl: image5, text1: subregionCounts[12] || '0', text2: 'SR East', to: '/sr-east' },
];
const toTitleCase = (str: any) => {
    if (str == null) {
        return ''; 
    }
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

if (loading) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
}
const stats = [
    {
      title: 'Total Leaders',
      value: numberLeadership,
      icon: UserCircle,
      gradient: 'from-blue-600 to-blue-400',
      shadowColor: 'shadow-blue-200'
    },
    {
      title: 'Total Ambassadors',
      value: numberAmbassador,
      icon: Award,
      gradient: 'from-emerald-600 to-emerald-400',

    },
    {
      title: 'Total Contacts',
      value: numberContacts,
      icon: ContactIcon,
      gradient: 'from-violet-600 to-violet-400',
   
    },
    {
      title: 'Total Users',
      value: totalUsers,
      icon: Users,
      gradient: 'from-orange-600 to-orange-400',
     
    }
  ];
  return (
    <>
        {User?.userType === 8 && (
            <>
       
        <div className="p-6" >
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
      {stats.map((stat, index) => (
        <div 
          key={index}
          className={`relative bg-white rounded-xl overflow-hidden transition-all duration-300 hover:scale-102 shadow-lg hover:shadow-xl`}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div className={`bg-gradient-to-r ${stat.gradient} p-3 rounded-lg`}>
                <stat.icon className="w-6 h-6 text-white" />
              </div>
            </div>
            <div className="space-y-2">
              <h3 className="text-gray-600 text-sm font-medium">
                {stat.title}
              </h3>
              <p className="text-black text-2xl font-semibold">
                {stat.value}
              </p>
            </div>
          </div>
          <div className={`absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r ${stat.gradient}`} />
        </div>
      ))}
    </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-12  containerr rounded-lg overflow-hidden shadow-lg bg-white w-[95%] ml-5 mt-6">
            
            {cardsData.map((card) => (
    <Link key={card.id} to={card.to || '#'}>
        <div className="flex-shrink-0 w-full ml-4 containerrr">
            <div className="shadow-lg rounded-lg overflow-hidden flex items-center p-2 space-x-4 sm:space-x-8 w-full containerr " style={{ background: '#1E71B8' }}>
                <img src={card.imageUrl} alt={`Card ${card.id}`} className="w-10 h-10 bg-white rounded-full" />
                <div className="p-1 text-white">
                    <p className="text-lg font-semibold">{card.text1}</p>
                    <p className='text2xl font-semibold'>{t (card.text2)}</p>
                </div>
            </div>
        </div>
    </Link>
))}
            </div>

            <div className='p-4'>
            </div>
            <div className="containerr rounded-lg overflow-hidden shadow-lg bg-white flex ml-5 w-[95%] ">
                <div className="  w-full  justify-center">
  <p className="text-2xl font-semibold p-3">{t('Contacts per Sub-Region')} </p>
                <MyChartComponent subregionCounts={subregionCounts} darkMode={darkMode}/>
                </div>
            </div>
            <div className="containerr  rounded-lg overflow-hidden w-[95%] shadow-lg bg-white flex  ml-5 mt-8 ">
                <div className="container mx-auto p-8">
                    <div className="px-6 py-4 w-full">
                        <div className='flex flex-row justify-between mb-7'>
                        <p className="font-bold text-xl mb-2" >Individuals</p>
                        <div>
                        <Select
    defaultValue=""
    className="w-40"
    style={{  marginRight: 16 }}
    onChange={(value) => setStatusFilter(value)}
>
    <Option value="">{t('All Status')}</Option>
    <Option value="leadership">{t('Leadership')}</Option>
    <Option value="ambassador">{t('Ambassador')}</Option>
    <Option value="Contacts">Contacts</Option>
    <Option value="Nation">{t('Nation')}</Option>
    <Option value="Sub-Region">{t('Sub-Region')}</Option>
</Select>
{issubRegionDropdownVisible?(
   <Select
    mode="multiple"
   placeholder="Select 
   sub_region"
   className="w-64"
   onChange={(sub) => setSubFilter(sub)}
   value={subFilter}
   >
     {subRegionList.map((sub:any) => (
        <Option key={sub.id} value={sub.name}>
        <div className="flex justify-between items-center">
            <span>{sub.name}</span>
            <input
                type="checkbox"
                checked={subFilter.includes(sub?.name)}
                onChange={() => handleSubCheckboxChange(sub?.name)}
            />
        </div>
    </Option>
     ))}
   </Select>
):(
    <Input
    placeholder="Search by name, email, position, or nationality"
    value={filterValue}
    onChange={(e) => setFilterValue(e.target.value)}
    style={{ width: 300 }}
    className="containerrr"
/>
)
}

</div>
{displayDelete===true && <div className="mb-4 flex flex-row gap-3">
           
           <button onClick={showMassDeleteModal} className="bg-red-500 text-white py-2 px-4 rounded">
               <FontAwesomeIcon icon={faTrash} className="mr-1" />
           </button>
           <button onClick={()=>setDisplayDelete(false)} className="bg-blue-500 text-white py-2 px-3 rounded  flex justify-center">
               <TiCancel className="w-6 h-6  " />
           </button>
   
       </div>}

                        </div>
                      <table className="w-full">
                        <thead className='text-left'>
                            <tr>
                                <th className="py-2">{t('NO')}</th>
                                <th className="py-2 px-3">{t('Name')}</th>
                                <th className="py-2">{t('Position')}</th>
                                <th className="py-2">
                                {isDropdownVisible ? (
    <Select
        mode="multiple"
        placeholder="Select Nationality"
        className="w-40"
        onChange={(values) => setMassFilter(values)}
        value={massFilter}
    >
        {nationList.map((nation:any) => (
            <Option key={nation.id} value={nation.name}>
                <div className="flex justify-between items-center">
                    <span>{nation.name}</span>
                    <input
                        type="checkbox"
                        checked={massFilter.includes(nation?.name)}
                        onChange={() => handleFilterCheckboxChange(nation?.name)}
                    />
                </div>
            </Option>
        ))}
    </Select>
) : 
<span>Nationality</span>
}

                </th>
                                {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) &&<th className='py-2'>Action</th>}
                            </tr>
                        </thead>
                        {paginatedData?.length > 0 ? (
                            <tbody>
                                {paginatedData.map((user, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-1">{(currentPage-1)*itemsPerPage+index + 1}</td>
                                        <td className="py-2 px-3">{toTitleCase(user.name)}</td>
                                        <td className="py-2 w-2/5">{toTitleCase((user.position || user.upfPosition) && (user.position || user.upfPosition).split(' ').slice(0, 120).join(' '))}
</td>
                                        <td className="py-2 w-1/4">{user.nationality && t(`${getNationNameById(user.nationality)}`)}</td>
                                        {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                           <td className="py-2 flex flex-row">
                                           {displayDelete===false &&  <FontAwesomeIcon
                                               onClick={() => showModal(user?.id)}
                                               className="cursor-pointer "
                                               color="#FF0000"
                                               icon={faTrash}
                                           />
}
                                           <button
                               className="text-blue-500 mr-4 ml-2"
                               onClick={() => navigate(`/editAmbassador/${user?.id}`)}
                           >
                               <CiEdit size={20} />
                           </button>
                         {displayDelete===true &&<input
                               type="checkbox"
                               checked={massDelete.includes(user?.id)}
                               onChange={() => handleCheckboxChange(user?.id)}
                           />}
                                       </td>
                                   )}
                               </tr>
                           ))}
                            </tbody>
                        ) : <Empty description="No Data Found" />}

                    </table>
                    <div className="flex justify-between items-center mt-4">
                    <Select
                        value={itemsPerPage}
                        style={{ width: 120 }}
                        onChange={handlePageSizeChange}
                    >
                        <Option value={10}>10 per page</Option>
                        <Option value={25}>25 per page</Option>
                        <Option value={50}>50 per page</Option>
                        <Option value={100}>100 per page</Option>
                    </Select>
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={usersData.length}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        style={{ textAlign: 'center' }}
                        itemRender={(page, type, originalElement) => {
                            if (type === 'page') {
                                return (
                                    <a
                                      className="paginateNumber "
                                    >
                                        {page}
                                    </a>
                                );
                            }
                            return originalElement;
                        }}
                    />
                </div>
                    <Modal
                title="Delete User"
                visible={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="many" type="primary" onClick={handleMany} className='bg-blue-500'>
                    Many
                </Button>,
                <Button key="delete" type="primary" danger onClick={handleDelete}>
                    Delete
                </Button>,
                ]}
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete only this user?</p>
            </Modal>
            <Modal
                title="Mass Delete Users"
                visible={isMassDeleteModalVisible}
                onOk={handleMassDelete}
                onCancel={() =>{setIsMassDeleteModalVisible(false);
                    setDisplayDelete(false)}}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete the selected users?</p>
            </Modal>

                    </div>
                </div>
            </div>
      
                </div>
            

        </>
        
     )}
     {(User?.userType === 2 ||User?.userType === 7) && User?.subRegionId=== 15 && navigate('/sr-west')}
     
     {(User?.userType === 2 ||User?.userType === 7) && User?.subRegionId=== 11  && navigate('/sr-central')}
{(User?.userType === 2 ||User?.userType === 7) && User?.subRegionId=== 12  && navigate('/sr-east')}
{(User?.userType === 2 ||User?.userType === 7)&& User?.subRegionId=== 13 && User?.regionId===1 && navigate('/sr-north')}
{(User?.userType === 2 ||User?.userType === 7)&& User?.subRegionId=== 14 && navigate('/sr-south')}
{(User?.userType === 5  || User?.userType === 6 || User?.userType === 3) &&  navigate(`/nation/${User.nationality}`)}
{/* {!(User?.userType === 8  ||(User?.userType === 6 ) ||(User?.userType === 3 )||(User?.userType === 5 )||(User?.userType === 7 )|| (User?.userType === 2)) && (
    navigate('/Association')
)} */}
 </>
 
);
};
export default MainPage;
