import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaChevronLeft } from "react-icons/fa";
import axios from "axios";

interface AmbassadorFormData {
  name: string;
  religion: string;
  gender: string;
  email: string;
  upfPosition: string;
  linkedInID:string;
  honorifics: string;
  username: string;
 primaryTag: number;
  nationality: number;
  picture: string;
  regionId: number;
  userType: number;
  subRegionId: number;
  mobileNo: string;
  leadershipStatus: boolean;
  ambassadorStatus: boolean;
  biography: string;
}
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
interface Nation {
  id: number;
  name: string;
}
const EditAmbassador: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [fetchedData, setFetchedData] = useState<AmbassadorFormData | null>(null);
  const [formData, setFormData] = useState<AmbassadorFormData | null>(null);
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const regionData = localStorage.getItem('subRegions');
  const regionOptions = regionData ? JSON.parse(regionData) : [];
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionOptions = subRegionData ? JSON.parse(subRegionData) : [];
  const nationData = localStorage.getItem('nations');
  const nationalityOptions = nationData ? JSON.parse(nationData) : [];
  const [biographyText, setBiographyText] = useState('');
  const [biographyType, setBiographyType] = useState('text');
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [imageURL, setImageURL] = useState<string>('');
  const [biographyFileURL, setBiographyFileURL] = useState<string>('');
 const handleBiographyTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
   
      setBiographyText(e.target.value)
   };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAmbassadorData = async () => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token not found in sessionStorage');
        return;
      }

      try {
        const response = await fetch(`https://api.upfafrica.org/admin/user/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.error('Failed to fetch ambassador data');
          return;
        }
        const responseData = await response.json();
      const { primaryTag, ...filteredData } = responseData.data;

      // Check if primaryTag is valid, set to 0 if not
      const updatedData = {
        ...filteredData,
        primaryTag: (primaryTag && !isNaN(primaryTag) && primaryTag !== "") ? primaryTag : 10,
      };

      setFetchedData(updatedData);
      setBiographyText(updatedData.biography)
      setFormData(updatedData);
      setBiographyFileURL(updatedData.linkedinID)
      setImageURL(updatedData.picture)
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchAmbassadorData();
  }, [id]);
  const handleBiographyFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload file with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`,
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload biography file');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastFileUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setBiographyFileURL(lastFileUrl);
   
        console.log('Biography file uploaded successfully:', lastFileUrl);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Biography file upload error:', error);
      // Optionally add user-facing error handling
    }
  };
  const handleSelectChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: name === 'nationality' ||name === 'primaryTag' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
  
    // For non-picture inputs, update form data as usual
    setFormData({
      ...formData!,
      [name]: type === 'checkbox' ? checked : name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_CREATE}`, 
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };


  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in sessionStorage');
    return;
  }

  try {
    const response = await fetch(`https://api.upfafrica.org/admin/user/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        ...formData!, 
        picture: imageURL, 
        biography: biographyText,
        linkedInID:biographyFileURL,
      }),
    });

    if (!response.ok) {
      const Error = await response.json();
      console.error('Failed to submit form', Error);
      return;
    }else{
    const responseData = await response.json();
    console.log("edit",responseData);
    navigate(`/individual/${id}`);  
    } 
  } catch (error) {
    console.error('Error:', error);
  }
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);
  if (!fetchedData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
  }
  const back=()=>{
    navigate(-1)
   }

  return (
    <>
     <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
           <p className='text-xl font-semibold ml-3'>Back</p>
          </button>

    <form className="max-w mx-auto p-4 " onSubmit={handleSubmit} style={getTitleStyle()}>
      <label className="block mb-2" >
        Name:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="name"
          value={formData?.name||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Religion:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="religion"
          value={formData?.religion||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Gender:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="gender"

          value={formData?.gender}
          onChange={handleSelectChange('gender')}
        >
          <option value=''>Select Gender</option>
          <option value='Male'>Male</option>
          <option value='Female'>Female</option>
        </select>
      </label>
      <label className="block mb-2" >
        Mobile No:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="mobileNo"
          value={formData?.mobileNo||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        UpfPosition:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="upfPosition"
          value={formData?.upfPosition||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Honorifics:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="honorifics"
          value={formData?.honorifics||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Email:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="email"
          name="email"
          value={formData?.email ||''}
         onChange={handleChange}
        />
      </label>

<div className="block mb-2">
              <label className="block font-medium mb-2">Biography:</label>
              <div className="flex mb-2 border-b">
                <button
                  type="button"
                  className={`px-4 py-2 ${biographyType === 'text' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                  onClick={() => setBiographyType('text')}
                >
                  Write Text
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 ${biographyType === 'file' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                  onClick={() => setBiographyType('file')}
                >
                  Upload File
                </button>
              </div>
              {biographyType === 'text' ? (
                <textarea
                  className="w-full p-2 border rounded-md mt-1"
                  rows={4}
                  value={biographyText}
                  onChange={handleBiographyTextChange}
                  placeholder="Enter your biography..."
                />
              ) : (
                <input
                  className="w-full p-2 border rounded-md mt-1"
                  type="file"
                  onChange={handleBiographyFileChange}
                />
              )}
              {biographyFileURL && (
  <div className="mt-2">
    <a 
      href={`https://fm.upfafrica.org${biographyFileURL} `} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="text-blue-500 hover:underline"
    >
      View Uploaded Biography
    </a>
  </div>
)}
            </div>

      <label className="block mb-2" >
        UserName:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="username"
          value={formData?.username||''}
          onChange={handleChange}
        />
        
      </label>
      <label className="block mb-2" >
        Leadership Status:
        <input
          className="ml-2"
          type="checkbox"
          name="leadershipStatus"
          checked={formData?.leadershipStatus}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Ambassador Status:
        <input
          className="ml-2"
          type="checkbox"
          name="ambassadorStatus"
          checked={formData?.ambassadorStatus}
          onChange={handleChange}
        />
      </label>
      {/* <label className="block mb-2" >
        Nationallty:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="number"
          name="nationality"
          value={formData?.nationality||''}
          onChange={handleChange}
        />
        
      </label> */}
     <label className="block mb-2">
  User Type:
  <select
    className="w-full p-2 border rounded-md mt-1"
    name="userType"
    value={formData?.userType}
    onChange={handleSelectChange('userType')}
  >
    <option value={0}>Select a user type</option>
    <option value={1}>Regional Coordinator</option>
    <option value={2}>SR Leader</option>
    <option value={3}>National Leader</option>
    <option value={4}>Editor</option>
    <option value={5}>National Coordinator</option>
    <option value={6}>National SG</option>
    <option value={7}>SR Admin</option>
    <option value={8}>Super Admin</option>
    <option value={9}>System User</option>
    <option value={10}>User</option>
  </select>
</label>

      {/* <label className="block mb-2" >
        PrimaryTag:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="number"
          name="primaryTag"
          value={formData?.primaryTag??''}
          onChange={handleChange}
        />
        
      </label> */}
      <label className="block mb-2" >
        Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="regionId"
          value={formData?.regionId}
          onChange={handleSelectChange('regionId')}
        >
          <option value="">Select a region</option>
          {regionOptions.map((region:any) => (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          ))}
        </select>
      </label>
      {/* Your existing form inputs */}
      <label className="block mb-2" >
        Sub-Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="subRegionId"
          value={formData?.subRegionId}
          onChange={handleSelectChange('subRegionId')}
        >
          <option value="">Select a sub-region</option>
          {subRegionOptions.map((subRegion:any) => (
            <option key={subRegion.id} value={subRegion.id}>
              {subRegion.name}
            </option>
          ))}
        </select>
      </label>
      <label className="block mb-2" >
        Nationality:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="nationality"
          value={formData?.nationality}
          onChange={handleSelectChange('nationality')}
        >
          <option value="">Select a nationality</option>
          {nationalityOptions.map((nationality:any) => (
            <option key={nationality.id} value={nationality.id}>
              {nationality.name}
            </option>
          ))}
        </select>
      </label>
      <label className="block mb-2">
  Association:
  <select
    className="w-full p-2 border rounded-md mt-1"
    name="primaryTag"
    value={formData?.primaryTag}
    onChange={handleSelectChange('primaryTag')}
  >
    <option key={0} value={1}>Select an Association</option>
    {associationList.map(association => (
      <option key={association.id} value={association.id}>
        {association.name}
      </option>
    ))}
  </select>
</label>


        <label htmlFor="picture" className="block mb-2">
  Picture:
  <input
    id="picture"
    className="w-full p-2 border rounded-md mt-1"
    // value={formData?.picture || ''}
    type="file"
    name="picture"
    onChange={handleImageChange}
  />
</label>

      <img src={`https://fm.upfafrica.org${imageURL}`||formData?.picture} alt="Uploaded" className='w-1/4 h-1/4 object-cover rounded-lg mb-4' />


      <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" type="submit">
        Submit
      </button>
    </form>
      
        </>
  );
};

export default EditAmbassador;
